<template>
  <div class="wrapper">
    <List
      :locations="locations"
      :activeId="activeId"
      @activeTracker="activeTracker"
    />
    <div>
      <AppMap
        height="100%"
        ref="AppMap"
        @ready="mapReady"
        style="padding-top: 0; height: 100%"
      >
        <LMarker
          :lat-lng="[location.location.latitude, location.location.longitude]"
          v-for="location of locations"
          @click="activeTracker(location._id)"
        >
          <l-icon :icon-anchor="[12, 12]" class-name="location-marker">
            <div class="marker-title" v-if="mapZoom >= 3">
              <div class="text-overflow">
                {{ location.driver.name }} {{ location.driver.lastname }}
              </div>
              <div
                class="text-overflow"
                v-if="mapZoom >= 8"
                style="font-size: 8px"
              >
                {{ location.driver.carrier.companyName }}
              </div>
            </div>

            <img :src="locationMarkerImg" class="marker" />
          </l-icon>
        </LMarker>
      </AppMap>
    </div>
  </div>
</template>

<script>
import { LMarker, LIcon, LPopup } from "@vue-leaflet/vue-leaflet";
import DriversMixin from "../../../mixins/Drivers/index";

import locationMarker from "../../../plugins/Maps/images/location-marker.png";

import List from "./components/List.vue";
import moment from "moment";

export default {
  components: {
    List,
    LMarker,
    LIcon,
    LPopup,
  },
  mixins: [DriversMixin],
  data() {
    return {
      locations: [],
      activeId: null,
      locationMarkerImg: locationMarker,
      map: null,
    };
  },
  computed: {
    mapZoom() {
      return this.map && this.map.zoom ? this.map.zoom : 0;
    },
  },
  watch: {
    activeId() {
      this.followActiveId();
    },
  },
  methods: {
    activeTracker(driverId) {
      this.activeId = driverId;
    },
    mapReady() {
      this.map = this.$refs["AppMap"];
    },
    followActiveId(zoomed = true) {
      const item = this.locations.find((item) => item._id === this.activeId);
      if (!item) return;

      if (zoomed)
        this.map.setView([item.location.latitude, item.location.longitude], 20);
      else this.map.panTo([item.location.latitude, item.location.longitude]);
    },
    getAllLocations() {
      this.drivers_get_all_locations((err, body) => {
        if (!err) {
          this.locations = body;
        }
      });
    },
    onLocationUpdates(data) {
      let matched = false;
      this.locations = this.locations.map((item) => {
        if (item.location.driver === data.driver) {
          matched = true;
          item.location = data;
        }
        return item;
      });

      if (!matched) {
        this.getAllLocations();
      } else if (this.activeId === data.driver) {
        this.followActiveId(false);
      }
    },
  },
  mounted() {
    this.getAllLocations();

    this.$WebSocket.on(
      "/dispatch/carriers/drivers/LocationUpdate",
      "DriversLocationMapComponent",
      this.onLocationUpdates
    );
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: calc(100vh - 60px);
  padding: 0;
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr);
  // grid-template-rows: auto auto minmax(0, 1fr);
}

.location-marker {
  position: relative;
  .marker {
    width: 24px;
    height: 24px;
  }
  .marker-content {
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translate(-50%, -5px);
    background: #fff;
    box-shadow: $boxShadow;
    border-radius: 10px;
    padding: 15px;
    width: 270px;
    .address {
      font-size: 10px;
      margin-top: 10px;
    }
    .date {
      font-size: 12px;
      color: $textLight;
    }
    .coordinates {
      font-size: 12px;
      color: $themeColor2;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  }
  .marker-title {
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translate(-50%, -5px);
    background: rgba(0, 0, 0, 0.5);
    max-width: 200px;
    text-align: center;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.8);
    padding: 2px 5px;
    font-size: 10px;
  }
}
</style>
