<template>
  <div class="locations-list">
    <div
      v-if="activeId && activeDriver"
      style="padding: 20px; display: grid; gap: 20px"
    >
      <ActionBar title="Tracking">
        <div style="display: flex; justify-content: end">
          <Button
            text="Back"
            size="small"
            @click="$emit('activeTracker', null)"
            theme="white"
          />
        </div>
      </ActionBar>

      <div>
        <div class="driver-wrapper">
          <div
            class="image"
            :style="[`background-image: url('${driverImage}')`]"
          ></div>
          <div class="name-group">
            <div class="name">
              {{ activeDriver.driver.name }} {{ activeDriver.driver.lastname }}
            </div>
            <div class="carrier" v-if="activeDriver.driver.carrier">
              {{ activeDriver.driver.carrier.companyName }}
            </div>
          </div>
        </div>

        <div class="driver-details">
          <div class="address">
            {{ activeDriver.location.displayName }}
          </div>

          <div class="footer">
            <a
              href="#"
              @click.prevent
              class="date"
              v-tooltip="{
                content: convertDate(activeDriver.location.date),
              }"
              >{{ locationTimeAgo(activeDriver.location.date) }}</a
            >
            <div class="coordinates">
              {{ activeDriver.location.latitude.toFixed(4) }},
              {{ activeDriver.location.longitude.toFixed(4) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-grid" v-else>
      <ListItem
        v-for="item of locations"
        :item="item"
        :key="item._id"
        :isActive="activeId === item._id"
        @click="$emit('activeTracker', item._id)"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ListItem from "./ListItem.vue";

import TimeAgo from "javascript-time-ago";
const timeAgo = new TimeAgo("en-US");

export default {
  props: ["locations", "activeId"],
  components: { ListItem },
  methods: {
    convertDate(date) {
      return moment(date).format("HH:mm DD/MM/YYYY");
    },
    locationTimeAgo(userDate) {
      let date = moment(userDate);
      return timeAgo.format(date.toDate());
    },
  },
  computed: {
    activeDriver() {
      const item = this.locations.find((item) => item._id === this.activeId);
      return item;
    },
    driverImage() {
      if (this.activeDriver && this.activeDriver.driver.image)
        return this.activeDriver.driver.image;
      return this.DEFAULTS.USER.IMAGE;
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .image {
    width: 120px;
    height: 120px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-self: center;
  }

  .name-group {
    display: grid;
    gap: 5px;
    .name {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
    .carrier {
      text-align: center;
      font-size: 14px;
    }
  }
}

.driver-details {
  margin-top: 20px;
  .address {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
  .date {
    font-size: 14px;
    color: $textLight;
  }
  .coordinates {
    font-size: 14px;
    color: $themeColor2;
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
  }
}

.locations-list {
  height: 100%;
  overflow-y: auto;
  background: #fff;
  border-right: 1px solid $borderColor;
  .list-grid {
  }
}
</style>
