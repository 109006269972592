<template>
  <div class="item" :class="[{ active: isActive }]">
    <div class="name text-overflow">
      {{ item.driver.name }} {{ item.driver.lastname }}
    </div>
    <div class="carrier text-overflow">
      {{ item.driver.carrier.companyName }}
    </div>
    <div class="address text-overflow">{{ item.location.displayName }}</div>
    <div class="timeago">{{ locationTimeAgo }}</div>
  </div>
</template>

<script>
import moment from "moment";
import TimeAgo from "javascript-time-ago";
const timeAgo = new TimeAgo("en-US");

export default {
  props: ["item", "isActive"],
  computed: {
    locationTimeAgo() {
      let date = moment(this.item.location.date);
      return timeAgo.format(date.toDate());
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 15px;
  border-bottom: 1px solid $borderColor;
  cursor: pointer;
  &:hover,
  &.active {
    background: $bg;
  }
  .name {
    font-size: 14px;
    font-weight: 500;
  }
  .carrier {
    font-size: 12px;
    margin-top: 2px;
  }
  .address {
    font-size: 10px;
    margin-top: 5px;
  }
  .timeago {
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
