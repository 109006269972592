export default {
  methods: {
    async drivers_get_details(driverId, callback) {
      await this.ajax(
        "Drivers_GetDriversDetails",
        {
          method: "GET",
          url: `/dispatch/carrier/drivers/${driverId}/details`,
        },
        callback
      );
    },
    async drivers_get_last_known_locations(driverId, callback) {
      await this.ajax(
        "Drivers_GetLastKnownLocations",
        {
          method: "GET",
          url: `/dispatch/carrier/drivers/${driverId}/tracking`,
        },
        callback
      );
    },
    async drivers_get_all_locations(callback) {
      await this.ajax(
        "Drivers_GetAllLocations",
        {
          method: "GET",
          url: `/dispatch/carrier/drivers/all/tracking`,
        },
        callback
      );
    },
  },
};
